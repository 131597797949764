<template>
  <div class="page store batch">
    <div class="ibox ">

      <div class="ibox-content">

        <div class="table-responsive">
          <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
            <thead>

              <tr class="fields text-left">
                <th :colspan="columns">

                  <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Resource Base URL</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="text" name="resource_base_url" v-model="resourceBaseUrl" />
                    </div>
                  </div>

                  <div class="form-group row right">
                    <label class="col-sm-2 col-form-label">Store Batch Upload File</label>
                    <div class="col-sm-10">
                      <div class="input-group batch-upload-input">
                        <div class="input form-control">
                          <div class="custom-file">
                            <label class="custom-file-label">
                              <input type="file" class="custom-file-input" @change="onChangeFile" />
                              <label class="custom-file-label">{{uploadFile && uploadFile.name}}</label>
                            </label>
                          </div>
                          
                        </div>
                        <span class="input-group-append">
                          <button class="btn btn-success btn-xs" @click="onUpload">업로드</button>
                        </span>
                      </div>
                    </div>
                  </div>

                </th>
              </tr>
            </thead>
            
            <thead v-if="items.length > 0">
              <tr>
                <th :colspan="columns">
                  <div class="info">Showing <strong>{{total}}</strong> entries</div>
                  <div class="padding"></div>
                </th>
              </tr>
              <tr class="fields">
                <th scope="col">#</th>
                <th scope="col">상점명</th>
                <th scope="col">결과</th>
              </tr>
            </thead>
            <tbody v-if="items.length > 0" :data-timestamp="timestamp">
              <template v-for="row in items">
                <tr :key="row.store_id" :class="{'has-settlement': row.has_settlements}">
                  <td>{{row._id}}</td>
                  <td>{{row.store_name_ko}}</td>
                  <td>
                    {{displayStatus(row.status)}}
                    <p v-if="row.error">{{row.error}}</p>
                  </td>
                </tr>

                <tr :key="row.store_id + '.data'"  v-if="row.opened">
                  <td :colspan="columns" class="row-detail">
                    
                  </td>
                </tr>
              </template>
            </tbody>

            <tfoot v-if="items.length > 0">
              <tr>
                <td :colspan="columns" class="actions">
                  <div class="left">
                    <button class="btn btn-white btn-sm" @click="onClickBack">Back</button>
                  </div>
                  <div class="right">
                    
                  </div>
                </td>
              </tr>
            </tfoot>

          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import Pagination from "@/components/elements/Pagination"

export default {
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model
  },
  data() {

    let resourceBaseUrl = this.getResourceBaseUrl()
    
    return {
      columns: 3,
      mode: 'unset',
      model: {},
      page: 1,
      size: 10,
      total: 0,
      data: {},
      items: [],
      resourceBaseUrl: resourceBaseUrl,
      uploadFile: null,
      sending: false,
      timestamp: +new Date()
    }
  },
  watch: {
    
  },
  computed: {
    
  },
  methods: {
    getResourceBaseUrl() {
      return `https://lugstay.s3.ap-northeast-2.amazonaws.com/${config.mode}`
    },
    displayStatus(status) {
      if (status === 'success') {
        return '성공'
      }

      return `실패`
    },

    onChangeFile(e) {
      if (e.target.files && e.target.files.length > 0) {
        this.$data.uploadFile = e.target.files[0]
      }
    },

    onUpload(e) {
      const formData = new FormData()
      formData.append('resource_base_url', this.resourceBaseUrl)
      formData.append('file', this.uploadFile)

      this.$data.sending = true
      this.$data.items = []

      this.$store.dispatch("store/batchUpload", formData)
        .then((data) => {
          let { error, items } = data
          if (error) {
            this.$toast.error(error.message)  
            return
          }

          this.$data.items = items
        })
        .catch((e) => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.sending = false
        })
    },

    onClickBack() {
      this.$router.back()
    },
  },
  components: {
    Pagination
  }
}
</script>

<style lang="scss" scoped>
.batch-upload-input {
  .form-control {
    background: none;
    border: none;
  }
}
</style>